export const theme = {
  palette: {
    white: '#ffffff',
    red: '#FF0000',
    light_gray: '#D2D2D2',
    gray: '#616161',
    light_green: '#68F8EF',
    green: '#24AEAE',
    dark_green: '#116062',
    dark_gray: '#1C1F1E',
    darker_dark_gray: '#141414',
    blue_90: '#212332',
    blue_80: '#2A2D3E',
    blue_70: '#333647',
    blue_60: '#3F4252',
    blue_50: '#5C5F71',
    blue_40: '#7C7F8E',
    blue_30: '#9396A4',
    blue_20: '#B2B4C3',
    blue_10: '#D8DAE3',
    gray_90: '#000000',
    gray_80: '#282827',
    gray_70: '#3E3E3E',
    gray_60: '#565656',
    gray_50: '#6F6F6E',
    gray_40: '#8C8C8C',
    gray_30: '#A4A4A4',
    gray_20: '#BEBEBE',
    gray_10: '#DCDCDC',
    black: '#000000'
  },
  fontFamily: {
    montserrat: 'Montserrat'
  },
  transition: {
    hover: 200,
    slide: 300,
    blink: 500,
    spin: 700,
    float: 5000
  },
  typography: {
    // H0 - H9
    h0: {
      fontSize: '38px',
      lineHeight: '57px',
      fontWeight: '800'
    },
    h1: {
      fontSize: '42px',
      lineHeight: '54px',
      fontWeight: '600'
    },
    h2: {
      fontSize: '42px',
      lineHeight: '54px',
      fontWeight: '300'
    },
    h3: {
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: '600'
    },
    h4: {
      fontSize: '32px',
      lineHeight: '39px',
      fontWeight: '700'
    },
    h5: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: '400'
    },
    h6: {
      fontSize: '32px',
      lineHeight: '48px',
      fontWeight: '600'
    },
    h7: {
      fontSize: '32px',
      lineHeight: '48px',
      fontWeight: '300'
    },
    h8: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '600'
    },
    h9: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '600'
    },
    // T0 - T8
    t0: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '400'
    },
    t1: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '300'
    },
    t2: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '400'
    },
    t3: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '600'
    },
    t4: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '400'
    },
    t5: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400'
    },
    t6: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: '300'
    },
    t7: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '400'
    },
    t8: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '300'
    },
    t9: {
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '300'
    }
  }
}
export type Theme = typeof theme

import { regExps } from 'app/shared/lib/validation'

export enum InputEnum {
  text = "text",
  tel = 'tel',
  email = 'email',
  number = 'number'
}

export enum ApplicationE {
  lastName = 'lastName',
  firstName = 'firstName',
  patronymic = 'patronymic',
  organization = 'organization',
  email = 'email',
  phone = 'phone',
  description = 'description',
  budget = 'budget'
}

export type ApplicationFormFieldsT = {
  lastName: string
  firstName: string
  patronymic: string
  organization: string
  email: string
  phone: string
  description: string
  budget: string
}

export type ApplicationFormDataT = {
  field: ApplicationE
  name: string
  placeholder?: string
  require?: boolean
  type: InputEnum
  pattern?: RegExp
  isBig?: boolean
}

export const applicationForm: ApplicationFormDataT[][] = [
  [
    {
      field: ApplicationE.lastName,
      name: 'Фамилия',
      placeholder: 'Константинов',
      require: true,
      type: InputEnum.text,
      pattern: regExps.initials
    },
    {
      field: ApplicationE.firstName,
      name: 'Имя',
      placeholder: 'Константин',
      require: true,
      type: InputEnum.text,
      pattern: regExps.initials
    },
    {
      field: ApplicationE.patronymic,
      name: 'Отчество',
      placeholder: 'Константинович',
      type: InputEnum.text,
      pattern: regExps.initials
    },
    {
      field: ApplicationE.organization,
      name: 'Компания',
      type: InputEnum.text,
      placeholder: 'OptimumData'
    },
    {
      field: ApplicationE.email,
      name: 'Email',
      placeholder: 'kostikkostochka@mail.ru',
      require: true,
      type: InputEnum.email,
      pattern: regExps.email
    },
    {
      field: ApplicationE.phone,
      name: 'Телефон',
      require: true,
      type: InputEnum.tel,
      pattern: regExps.phone
    },
    {
      field: ApplicationE.description,
      name: 'Опишите задание',
      isBig: true,
      type: InputEnum.text,
      placeholder: 'Требуется разработать мобильное приложение и web-кабинет для автоматизации бизнес-процессов компании'
    },
    {
      field: ApplicationE.budget,
      name: 'Планируемый бюджет, ₽',
      placeholder: '300 000',
      type: InputEnum.number,
      pattern: regExps.number
    }
  ],
  [
    {
      field: ApplicationE.lastName,
      name: 'Last name',
      require: true,
      type: InputEnum.text,
      pattern: regExps.initials
    },
    {
      field: ApplicationE.firstName,
      name: 'First name',
      require: true,
      type: InputEnum.text,
      pattern: regExps.initials
    },
    {
      field: ApplicationE.patronymic,
      name: 'Patronymic',
      type: InputEnum.text,
      pattern: regExps.initials
    },
    {
      field: ApplicationE.organization,
      name: 'Company',
      type: InputEnum.text,
      placeholder: 'OptimumData'
    },
    {
      field: ApplicationE.email,
      name: 'Email',
      placeholder: 'email@email.com',
      require: true,
      type: InputEnum.email,
      pattern: regExps.email
    },
    {
      field: ApplicationE.phone,
      name: 'Phone',
      require: true,
      type: InputEnum.tel,
      pattern: regExps.phone
    },
    {
      field: ApplicationE.description,
      name: 'Job description',
      isBig: true,
      type: InputEnum.text,
      placeholder: 'We need to develop a mobile application and web-office to automate business processes of the company'
    },
    {
      field: ApplicationE.budget,
      name: 'Planned budget, $',
      placeholder: '30 000',
      type: InputEnum.number,
      pattern: regExps.number
    }
  ]
]

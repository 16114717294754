import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { setModalOpen } from 'store'
import { theme } from 'ui/styles'

import { useTypeWriter } from '../typeWriter'

import {
  Wrapper,
  StyledSprite1,
  StyledSphere1,
  StyledSphere2,
  TitleSection,
  StyledTitle,
  StyledText,
  OrderButton,
  StyledSphere3,
  ProjectSection,
  SectionTitle,
  LogoWrapper,
  ODLogo,
  OptimumDataText,
  ExperienceTitle,
  ExperienceText,
  FactRow,
  FactItem,
  FactTitle,
  FactInfo
} from './AboutUs.styles'

export type ItemT = {
  title: string
  info: string
}

const TITLE: string[] = ['OPTIMUM DATA Разработчик', 'OPTIMUM DATA Developer of']
const TITLE_TYPINGS: string[][] = [
  ['программного обеспечения', 'веб-сервисов', 'мобильных приложений'],
  ['software', 'web services', 'mobile applications']
]

const FACTS: ItemT[][] = [
  [
    { title: '50+', info: 'Сотрудников в нашей команде' },
    { title: 'с 2014 года', info: 'Мы помогаем бизнесу создавать it-сервисы' },
    { title: '6 юнитов', info: 'В компании занимаются разными направлениями' },
    { title: '40+', info: 'Постоянных клиентов' }
  ],
  [
    { title: '50+', info: 'Employees on our team' },
    { title: 'since 2014', info: 'We help businesses create IT-services' },
    { title: '6 units', info: 'The company is engaged in different areas' },
    { title: '40+', info: 'Implemented projects' }
  ]
]
const EXPERIENCE_TITLE = ['Разрабатываем сложные интеграционные проекты', 'We develop complex integration projects']

const ORDER_LABEL = ['Заказать проект', 'Order a project']

const EXPERIENCE_DESCRIPTION = [
  [
    'Наш опыт — 8 лет разработки для ведущих российских компаний, в том числе в банковском секторе, ритейле, промышленности, фармацевтической отрасли, транспорте и логистике.',
    'Мы вникаем в бизнес-процессы заказчика, что позволяет покрыть весь цикл реализации: от бизнес-аналитики до разработки и внедрения прикладного ИТ-решения.',
    'Обладая, экспертизой и большим опытом, мы предложим оптимальное технические решения для вашей компании.'
  ],
  [
    'Our experience — 8 years of development for the leading Russian companies, including in the banking sector, retail, industry, pharmaceutical industry, transport and logistics.',
    'We deeply understand the customer`s business processes, which allows us to cover the whole implementation cycle: from business intelligence to development and implementation of the applied IT-solution.',
    'With our expertise and extensive experience, we will offer the best technical solutions for your company.'
  ]
]

export const AboutUs = () => {
  const language = useSelector((state: RootState) => state.language.language)
  const { displayText } = useTypeWriter(TITLE_TYPINGS[language])

  const dispatch = useDispatch()

  const onApplicationClick = () => {
    dispatch(setModalOpen({ modalIsOpen: true, modalType: { type: 'application' } }))
  }

  return (
    <Wrapper>
      <StyledSprite1 />
      <StyledSphere1 name="spheres/sphere1.png" width="100%" height="100%" />
      <StyledSphere2 name="spheres/sphere2.png" width="100%" height="100%" />
      <TitleSection>
        <StyledTitle variant="h1" inline>
          {TITLE[language]}
        </StyledTitle>
        <StyledText variant="h1" inline>
          {displayText}
        </StyledText>
        <OrderButton onClick={onApplicationClick} isBig>
          {ORDER_LABEL[language]}
        </OrderButton>
      </TitleSection>

      <StyledSphere3 name="spheres/sphere3.png" width="100%" height="100%" />
      <ProjectSection>
        <SectionTitle>
          <LogoWrapper>
            <ODLogo />
            <OptimumDataText variant="h2">OPTIMUM DATA</OptimumDataText>
          </LogoWrapper>
          <ExperienceTitle variant="h1">{EXPERIENCE_TITLE[language]}</ExperienceTitle>
        </SectionTitle>
        <ExperienceText variant="t1" color={theme.palette.light_gray}>
          {EXPERIENCE_DESCRIPTION[language].map((el) => (
            <p>{el}</p>
          ))}
        </ExperienceText>
        <FactRow>
          {FACTS[language].map((el, i) => (
            <FactItem key={i} direction="bottom">
              <FactTitle variant="h4">{el.title}</FactTitle>
              <FactInfo variant="t2">{el.info}</FactInfo>
            </FactItem>
          ))}
        </FactRow>
      </ProjectSection>
    </Wrapper>
  )
}

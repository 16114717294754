import { FC, MutableRefObject, PropsWithChildren, useRef } from 'react'

import { useIsInViewport } from 'app/shared'
import { theme } from 'ui/styles'

type Directions = 'top' | 'right' | 'bottom' | 'left'

type Props = {
  direction: Directions
  className?: string
}

const getFadeLeftStyles = (inViewport: boolean) => ({
  transition: `all ${theme.transition.slide}ms ease`,
  opacity: inViewport ? '1' : '0',
  transform: inViewport ? '' : 'translateX(-100%)'
})

const getFadeRightStyles = (inViewport: boolean) => ({
  transition: `all ${theme.transition.slide}ms ease`,
  opacity: inViewport ? '1' : '0',
  transform: inViewport ? '' : 'translateX(100%)'
})

const getFadeTopStyles = (inViewport: boolean) => ({
  transition: `all ${theme.transition.slide}ms ease`,
  opacity: inViewport ? '1' : '0',
  transform: inViewport ? '' : 'translateY(100%)'
})
const getFadeBottomStyles = (inViewport: boolean) => ({
  transition: `all ${theme.transition.slide}ms ease`,
  opacity: inViewport ? '1' : '0',
  transform: inViewport ? '' : 'translateY(-100%)'
})

const getStyles = (direction: Directions, isViewport: boolean) => {
  switch (direction) {
    case 'right': {
      return getFadeRightStyles(isViewport)
    }
    case 'bottom': {
      return getFadeBottomStyles(isViewport)
    }
    case 'left': {
      return getFadeLeftStyles(isViewport)
    }
    default: {
      return getFadeTopStyles(isViewport)
    }
  }
}

export const SlideAnimation: FC<PropsWithChildren<Props>> = ({ children, direction = 'top', className }) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  const inViewport = useIsInViewport(ref)

  return (
    <div className={className} ref={ref} style={getStyles(direction, inViewport)}>
      {children}
    </div>
  )
}

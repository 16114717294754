import { createRef } from 'react'

import { AboutUs } from './AboutUs'
import { Directions } from './Directions'
import { Technologies } from './Technologies'

export type SectionT = {
  id: string
  el: JSX.Element
  title: string
  tileRef: React.RefObject<HTMLDivElement>
}

const directionsRef = createRef<HTMLDivElement>()
const technologiesRef = createRef<HTMLDivElement>()
const clientsRef = createRef<HTMLDivElement>()

export const Tiles = [
  { aboutUs: 'О нас', directions: 'Направления', technologies: 'Технологии' },
  {
    aboutUs: 'About us',
    directions: 'Directions',
    technologies: 'Technology'
  }
]

export const getSections = (language: number): SectionT[] => [
  { id: 'aboutUs', title: Tiles[language].aboutUs, tileRef: directionsRef, el: <AboutUs /> },
  { id: 'directions', title: Tiles[language].directions, tileRef: technologiesRef, el: <Directions /> },
  { id: 'technologies', title: Tiles[language].technologies, tileRef: clientsRef, el: <Technologies /> }
]

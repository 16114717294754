import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { breakpoints } from 'app/shared'
import { Image, Text } from 'ui/components'
import { getStyles, styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e7f6fc;

  padding: 128px 138px 96px;
  width: 100%;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 64px 33px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 64px 24px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 64px 16px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 596px;
`

const Title = styled(Text)`
  color: black;
  text-transform: uppercase;

  &::before {
    content: '—';
    color: ${theme.palette.green};
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('h9')};
  }
`

const TitleDescription = styled(Text)`
  margin-top: 16px;
  color: black;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('t5')};
  }
`

const TechnologiesData = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-top: 56px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
    margin-top: 8px;
  }
`

const TechColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const ColumnTitle = styled(Text)`
  text-transform: uppercase;
  color: ${theme.palette.green};
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-bottom: 16px;

    margin-top: 24px;
  }
`

const ColumnItem = styled.div`
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const ItemIcon = styled(Image)`
  width: 48px;
  margin-right: 24px;
  user-select: none;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 32px;
    margin-right: 8px;
  }
`

const ItemName = styled(Text)`
  color: black;
  text-transform: uppercase;
`

type TechnologiesGroupT = {
  groupName: string
  technologies: {
    iconName: string
    techName: string
  }[]
}

const TECHNOLOGIES: TechnologiesGroupT[] = [
  {
    groupName: 'Devops',
    technologies: [
      { techName: 'Docker', iconName: 'docker' },
      { techName: 'kubernetes', iconName: 'kubernetes' },
      { techName: 'salt', iconName: 'salt' },
      { techName: 'terraform', iconName: 'terraform' },
      { techName: 'prometheus', iconName: 'prometheus' },
      { techName: 'ansible', iconName: 'ansible' }
    ]
  },
  {
    groupName: 'back-end',
    technologies: [
      { techName: 'python', iconName: 'python' },
      { techName: 'go', iconName: 'go' },
      { techName: 'postgres', iconName: 'postgres' },
      { techName: 'mongo', iconName: 'mongo' },
      { techName: 'mysql', iconName: 'mysql' },
      { techName: 'tarantool', iconName: 'tarantool' }
    ]
  },
  {
    groupName: 'front-end',
    technologies: [
      { techName: 'react', iconName: 'react' },
      { techName: 'typescript', iconName: 'typescript' },
      { techName: 'redux', iconName: 'redux' },
      { techName: 'mobx', iconName: 'mobx' },
      { techName: 'apollo', iconName: 'apollo' }
    ]
  },
  {
    groupName: 'android',
    technologies: [
      { techName: 'kotlin', iconName: 'kotlin' },
      { techName: 'rxjava', iconName: 'rxjava' },
      { techName: 'dagger', iconName: 'dagger' },
      { techName: 'gradle', iconName: 'gradle' },
      { techName: 'jetpack', iconName: 'jetpack' }
    ]
  },
  {
    groupName: 'ios',
    technologies: [
      { techName: 'swift', iconName: 'swift' },
      { techName: 'rxswift', iconName: 'rxswift' },
      { techName: 'swnject', iconName: 'swnject' },
      { techName: 'realmdb', iconName: 'realmdb' }
    ]
  }
]
const TITLE: string[] = ['Технологии', 'Technologies']

const TITLE_DESCRIPTION: string[] = [
  'Мы используем только актуальные технологии, позволяющие бизнесу быть на острие it-прогресса',
  'We use only the latest technologies that allow businesses to be at the cutting edge of IT-progress'
]

export const Technologies = () => {
  const language = useSelector((state: RootState) => state.language.language)

  return (
    <Wrapper>
      <TitleWrapper>
        <Title variant="h1">{TITLE[language]}</Title>
        <TitleDescription variant="t0">{TITLE_DESCRIPTION[language]}</TitleDescription>
      </TitleWrapper>
      <TechnologiesData>
        {TECHNOLOGIES.map((group) => (
          <TechColumn key={group.groupName}>
            <ColumnTitle variant="t3">{group.groupName}</ColumnTitle>
            {group.technologies.map((item) => (
              <ColumnItem key={item.techName}>
                <ItemIcon isIcon name={`technologies/${item.iconName}`} />
                <ItemName variant="t4">{item.techName}</ItemName>
              </ColumnItem>
            ))}
          </TechColumn>
        ))}
      </TechnologiesData>
    </Wrapper>
  )
}

import { createReducer, ActionType } from 'typesafe-actions'

import * as loader from './actions'

export type LoaderAction = ActionType<typeof loader>

/**
 * Номер локализации
 * 0 - Русский
 * 1 - Английский
 */
export type LanguageState = {
  language: number
}

const initialState: LanguageState = {
  language: 1
}

export const languageReducer = createReducer<LanguageState>(initialState).handleAction(
  loader.setLanguage,
  (state: LanguageState, { payload }: any) => ({
    ...state,
    language: payload
  })
)

import { css } from 'styled-components'
import { styled } from 'ui/styles'

export const Divider = styled.div<{ withMargin?: boolean; color?: string }>`
  width: 100%;
  height: 2px;
  background: ${({ color }) => (color ? color : '#212121')};

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 24px 0;
    `}
`

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { setActiveSection } from 'store'
import { getSections } from 'app/features'

type FX = {
  startTime: number
  duration: number
  startScroll: number
  endScroll: number
}

export const useScroll = () => {
  const dispatch = useDispatch()

  const activeSection = useSelector((state: RootState) => state.section.activeSection)
  const language = useSelector((state: RootState) => state.language.language)

  let fx: FX | null = null

  React.useEffect(() => {
    const handleScroll = (): void => {
      if (fx) return
      for (let i = getSections(language).length - 1; i > 0; i--) {
        const target = document.getElementById(getSections(language)[i].id)!

        if (window.scrollY >= target.offsetTop - target.offsetHeight / 2) {
          if (activeSection !== i) {
            dispatch(setActiveSection(i))
          }
          return
        }
      }
      if (activeSection !== 0) {
        dispatch(setActiveSection(0))
      }
    }

    window.removeEventListener('scroll', handleScroll)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [activeSection, dispatch, fx, language])

  const handleNewAnimationFrame = (): void => {
    const now = Date.now()
    const timePos = Math.min(1, (now - fx!.startTime) / fx!.duration)
    const scrollPos = 1 - Math.pow(1 - timePos, 3) // easing
    const scroll = fx!.startScroll + (fx!.endScroll - fx!.startScroll) * scrollPos - 68 // "68" is appBar height
    window.scrollTo({ top: scroll })

    if (timePos === 1) {
      fx = null
    } else {
      window.requestAnimationFrame(handleNewAnimationFrame)
    }
  }
  const goTo = (i: number): void => {
    const id = getSections(language)[i].id
    const target = document.getElementById(id)!

    if (!fx) {
      window.requestAnimationFrame(handleNewAnimationFrame)
    }

    fx = {
      startTime: Date.now(),
      duration: 700,
      startScroll: window.scrollY + 68, // "68" is appBar height
      endScroll: target.offsetTop
    }

    dispatch(setActiveSection(i))
  }

  return { activeSection, goTo, handleNewAnimationFrame }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { setModalOpen } from 'store'

import { breakpoints } from 'app/shared'
import { getSections } from 'app/features/sections'
import { styled, theme } from 'ui/styles'

import { AppBarItem } from '../atoms'

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: inline-flex;
  width: auto;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    position: fixed;
    z-index: 99;
    flex-direction: column;

    top: 60px;
    padding: 26px 36px;

    overflow-x: hidden;

    left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};

    transition: transform, ${theme.transition.hover}ms ease;
  }
`

const APPLICATION_TITLE = ['Оставить заявку', 'Leave a request']

type Props = {
  burgerIsOpened: boolean
  handleItemClick: (i: number) => void
}

export const AppBarItems: React.FC<Props> = ({ burgerIsOpened, handleItemClick }) => {
  const state = useSelector((state: RootState) => state)
  const language = useSelector((state: RootState) => state.language.language)

  const dispatch = useDispatch()

  const onApplicationClick = () => {
    handleItemClick(state.section.activeSection)
    dispatch(setModalOpen({ modalIsOpen: true, modalType: { type: 'application' } }))
  }

  return (
    <Wrapper isOpen={burgerIsOpened}>
      {getSections(language).map((tile, i) => (
        <AppBarItem key={tile.title} title={tile.title} onClick={() => handleItemClick(i)} />
      ))}
      <AppBarItem title={APPLICATION_TITLE[language]} onClick={() => onApplicationClick()} />
    </Wrapper>
  )
}

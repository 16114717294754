import React from 'react'
import { RootState } from 'root-reducer'
import { useSelector } from 'react-redux'

import { Divider, Text } from 'ui/components'
import { styled } from 'ui/styles'
import { breakpoints } from 'app/shared'

import { Contact, ContactPropsT } from './Contact'

import { ReactComponent as GeoIcon } from 'ui/icons/contacts/geo.svg'
import { ReactComponent as PhoneIcon } from 'ui/icons/contacts/phone.svg'

const Wrapper = styled.footer`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`

const UpperPart = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 128px 138px 24px;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 96px 32px 24px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 96px 24px 24px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
    padding: 64px 24px 24px;
  }
`

const Company = styled(Text)`
  height: 60px;
`

const LowerPart = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 138px 14px;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 24px 32px 24px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 24px 24px 24px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 24px 16px 32px;
    flex-direction: column;
  }
`

const Сopyright = styled(Text)`
  margin: 0 120px 0 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: 0 0 16px 0;
  }
`

const contacts: ContactPropsT[][] = [
  [
    { title: 'Телефон', contact: '+374(93)221738', icon: <PhoneIcon /> },
    { title: 'Где мы находимся', contact: 'Армения, Ереван, Шенгавит, ул. Ширака 74/2', icon: <GeoIcon /> }
  ],
  [
    { title: 'Phone', contact: '+374(93)221738', icon: <PhoneIcon /> },
    {
      title: 'Where are we located',
      contact: ' 74/2 Shirak Str., Shengavit, Yerevan, Armenia',
      icon: <GeoIcon />
    }
  ]
]

export const Footer: React.FC = () => {
  const language = useSelector((state: RootState) => state.language.language)

  return (
    <Wrapper>
      <Content>
        <UpperPart>
          <Company variant="h0">OD</Company>
          {contacts[language].map((el, i) => (
            <Contact key={i} {...el} />
          ))}
        </UpperPart>
        <Divider />
        <LowerPart>
          <Сopyright variant="t6">© 2014-2022 Optimum Data</Сopyright>
        </LowerPart>
      </Content>
    </Wrapper>
  )
}

import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { ApplicationE, ApplicationFormDataT, ApplicationFormFieldsT, InputEnum } from 'data/application'
import { breakpoints } from 'app/shared'
import { styled } from 'ui/styles'

import { Text } from '../Text'
import { PhoneField } from './PhoneInput'
import { Image } from '../Image'
import { Button } from '../Button'
import { Textarea } from './Textarea'

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

const Label = styled(Text)`
  display: flex;
  max-width: 240px;
  width: 100%;
  margin-top: 8px;
`

const TextAreaWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

const ClearIcon = styled(Image)<{ right?: number }>`
  position: absolute;
  right: ${({ right }) => right}px;
  top: 8px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.6);
    }
  }
`

type Props = {
  input: ApplicationFormDataT
  control: Control<ApplicationFormFieldsT, object>
  clearField: () => void
  isValid: boolean
}

export const TextInput: React.FC<Props> = ({ input, control, clearField, isValid }) => {
  const isPhone = input.type === InputEnum.tel

  return (
    <Wrapper>
      <Label variant="t7" color="black" inline>
        {input.name}
        {input.require ? '*' : ''}
      </Label>
      <Controller
        control={control}
        name={input.field as ApplicationE}
        rules={{
          required: input.require,
          pattern: input.pattern,
          validate: (value: string) => (input.require ? !!value.trim() : true)
        }}
        render={({ field }) => (
          <TextAreaWrapper>
            {isPhone ? (
              <PhoneField value={field.value} isValid={isValid} field={field} />
            ) : (
              <Textarea
                value={field.value}
                isValid={isValid}
                isBig={input.isBig}
                placeholder={input.placeholder}
                field={field}
              />
            )}
            {Boolean(field.value) && (
              <Button onClick={() => field.onChange('')}>
                <ClearIcon name="close_small" right={isPhone ? 60 : 16} alt="clear" isIcon />
              </Button>
            )}
          </TextAreaWrapper>
        )}
      />
    </Wrapper>
  )
}

import { getStyles, styled, theme } from 'ui/styles'
import { breakpoints } from 'app/shared'

import { blinking, floating, Image, OutlineButton, SlideAnimation, Text } from 'ui/components'

import { ReactComponent as Sprite1 } from 'ui/icons/sprite1.svg'
import { ReactComponent as LogoBig } from 'ui/icons/logo_big.svg'

export const StyledSprite1 = styled(Sprite1)`
  position: absolute;
  width: 626px;
  height: 504px;
  right: 0;
  top: 12px;
  z-index: 1;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 392px;
    height: 315px;

    transform: translate(0px, 180px);
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 240px;
    height: 200px;
  }
`

export const StyledSphere1 = styled(Image)`
  position: absolute;
  width: 239px;
  height: 239px;
  top: 52px;
  right: 480px;
  z-index: 0;
  transition: left, top, ${theme.transition.slide}ms ease;

  /* animation: ${floating} ${theme.transition.float}ms ease-in-out infinite; */

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 150px;
    height: 150px;
    transform: translate(180px, 180px);
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 89px;
    height: 89px;
    transform: translate(320px, 180px);
  }
`

export const StyledSphere2 = styled(Image)`
  position: absolute;
  width: 153px;
  height: 153px;
  top: 433px;
  right: 34px;
  z-index: 0;
  transition: left, top, ${theme.transition.slide}ms ease;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 96px;
    height: 96px;
    transform: translate(0px, 30px);
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 57px;
    height: 57px;
    transform: translate(20px, -70px);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & > img {
    user-select: none;
  }
`

export const TitleSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 174px 138px 96px;
  width: 100%;
  z-index: 1;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 86px 33px 96px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 86px 24px 96px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 86px 16px 96px;
  }
`

export const StyledTitle = styled(Text)`
  width: fit-content;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('h6')}
  }
`

export const StyledText = styled(Text)`
  user-select: none;
  height: 216px;
  max-width: 780px;
  width: 100%;
  text-transform: uppercase;

  &::after {
    content: '|';
    animation: ${blinking} 1s step-start infinite;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${getStyles('h6')}
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 250px;
  }
`

export const OrderButton = styled(OutlineButton)`
  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 100%;
    max-width: unset;
    min-height: 48px;
  }
`

export const StyledSphere3 = styled(Image)`
  position: absolute;
  width: 895px;
  height: 895px;
  left: -229px;
  top: 610px;
  z-index: 0;

  transition: left, top, ${theme.transition.slide}ms ease;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    top: 700px;
    left: -302px;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 384px;
    height: 384px;
    top: 510px;
    left: -128px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    top: 610px;
    left: -190px;
  }
`

export const ProjectSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 96px 138px 0px;
  width: 100%;
  z-index: 2;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 240px 33px 0px;
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 50px 24px 0px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 50px 16px 0px;
  }
`

export const SectionTitle = styled.div`
  display: inline-flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    align-items: flex-start;
  }
`

export const ODLogo = styled(LogoBig)`
  position: relative;
  display: flex;
  width: 288px;
  height: 130px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 195px;
    height: 89px;
  }
`
export const OptimumDataText = styled(Text)`
  position: absolute;
  width: 215px;
  top: 160px;
  text-align: end;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    ${getStyles('h7')}
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 100px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    text-align: unset;
    width: 100%;
  }
`

export const ExperienceTitle = styled(Text)`
  display: inline-flex;
  margin-left: 56px;
  text-transform: uppercase;

  &::before {
    content: ' — ';
    margin-right: 16px;
  }

  @media (max-width: ${breakpoints.desktop - 1}px) {
    ${getStyles('h6')}
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('h9')}
    margin-left: unset;
    margin-top: 60px;
  }
`

export const ExperienceText = styled(Text)`
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  width: 50%;
  margin-top: 40px;

  p {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${breakpoints.desktop - 1}px) {
    width: 65%;
    margin-top: 130px;
  }

  @media (max-width: ${breakpoints.desktop - 1}px) {
    ${getStyles('t6')};
  }
  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 80%;
    margin-top: 110px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 100%;
    margin-top: 16px;
  }
`

export const FactRow = styled.div`
  display: grid;
  justify-content: space-between;
  margin-top: 64px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    grid-template-columns: 1fr;
  }
`

export const FactItem = styled(SlideAnimation)`
  width: 100%;
`

export const FactTitle = styled(Text)`
  margin-bottom: 24px;
`
export const FactInfo = styled(Text)`
  color: ${theme.palette.light_green};
  text-transform: uppercase;
`

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppBar, ApplicationModal, Footer, getSections } from 'app/features'
import { StatusModal } from 'app/features/modals/StatusModal'
import { styled } from 'ui/styles'
import { Modal } from 'ui/components'

import { RootState } from 'root-reducer'
import { setModalOpen } from 'store'

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
`

export const App: React.FC = () => {
  const modalType = useSelector((state: RootState) => state.modal.modalType)
  const modalIsOpen = useSelector((state: RootState) => state.modal.modalIsOpen)
  const language = useSelector((state: RootState) => state.language.language)
  const dispatch = useDispatch()

  const onOverlayClick = () => {
    dispatch(setModalOpen({ modalIsOpen: false }))
  }

  return (
    <Wrapper>
      <AppBar />
      {getSections(language).map((section, i) => (
        <div key={i} ref={section.tileRef} id={section.id}>
          {section.el}
        </div>
      ))}
      <Footer />
      <Modal contentMaxWidth="704px" isVisible={modalIsOpen} onOverlayClick={onOverlayClick}>
        {modalType?.type === 'status' ? (
          <StatusModal />
        ) : (
          <ApplicationModal onClose={onOverlayClick}></ApplicationModal>
        )}
      </Modal>
    </Wrapper>
  )
}

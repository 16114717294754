import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { setModalOpen } from 'store'
import { Image, OutlineButton, RoundedButton, Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { ModalTemplate } from './templates'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 370px;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const StatusIcon = styled(Image)<{ error: boolean }>`
  display: flex;
  width: 94px;
  height: 94px;
  object-fit: scale-down;
  border-radius: 50%;
  background-color: ${({ error }) => (error ? `${theme.palette.blue_20}` : `${theme.palette.green}`)};
  margin-bottom: 24px;

  svg {
    width: 36px;
    height: 36px;
  }
`

const Status = styled(Text)``
const StatusInfo = styled(Text)`
  margin-bottom: 32px;
`

const Header = styled.span`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
`

const HEADER_SUCCESS = ['Спасибо!', 'Thanks!']
const HEADER_ERROR = ['Упс!', 'Oops!']

const DESCRIPTION_SUCCESS = ['Мы скоро свяжемся с вами', 'We will contact you soon']
const DESCRIPTION_ERROR = ['Что-то пошло не так', 'Something went wrong']

const SUBMIT_LABEL = ['Хорошо', 'OK']

export const StatusModal = () => {
  const language = useSelector((state: RootState) => state.language.language)
  const modalState = useSelector((state: RootState) => state.modal)
  const dispatch = useDispatch()

  const isError = modalState.modalType?.status?.isError

  const onClose = () => {
    dispatch(setModalOpen({ modalIsOpen: false }))
  }

  return (
    <ModalTemplate onClose={onClose}>
      <Wrapper>
        <Content>
          <Header>
            <RoundedButton onClick={onClose} isOutline />
          </Header>
          <StatusIcon name={`status/${isError ? 'error' : 'check'}`} error={isError ? 1 : 0} isIcon fill="cover" />
          <Status variant="h5" color="black">
            {isError ? `${HEADER_ERROR[language]}` : `${HEADER_SUCCESS[language]}`}
          </Status>
          <StatusInfo variant="h5" color="black" align="center">
            {isError ? `${DESCRIPTION_ERROR[language]}` : `${DESCRIPTION_SUCCESS[language]}`}
          </StatusInfo>
          {isError && (
            <Text variant="t2" color="black">
              {modalState.modalType?.status?.message}
            </Text>
          )}
        </Content>
        <OutlineButton onClick={onClose}>{SUBMIT_LABEL[language]}</OutlineButton>
      </Wrapper>
    </ModalTemplate>
  )
}

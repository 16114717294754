import React, { PropsWithChildren } from 'react'

import { Divider, Loader, RoundedButton } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CloseButton = styled(RoundedButton)``

type Props = {
  onClose(): void
  header?: React.ReactNode
  isLoading?: boolean
}

export const ModalTemplate: React.FC<PropsWithChildren<Props>> = ({ header, onClose, isLoading = false, children }) => {
  return (
    <Wrapper>
      {isLoading && <Loader />}
      {header && (
        <>
          <HeaderWrapper>
            <TitleWrapper>{header}</TitleWrapper>
            <CloseButton onClick={onClose} isOutline />
          </HeaderWrapper>
          <Divider color={theme.palette.blue_10} withMargin />
        </>
      )}
      {children}
    </Wrapper>
  )
}

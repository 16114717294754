import React from 'react'
import { css } from 'styled-components'

import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'
import { breakpoints } from 'app/shared'

import { LanguageBar } from '../atoms'

const Wrapper = styled.div<{ isOpened: boolean }>`
  display: inline-flex;
  align-items: center;
  user-select: none;
  background-color: transparent;
  padding: 6px;

  transition: background-color ${theme.transition.hover}ms ease;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  /* triangle */
  &::after {
    content: '';
    display: flex;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 2px 0 2px;
    border-color: ${theme.palette.white} transparent transparent transparent;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.palette.green};
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      background-color: ${theme.palette.green};
    `}
`

export const LanguagePicker: React.FC = () => {
  const [languageIsOpened, setLanguageIsOpened] = React.useState<boolean>(false)

  const handleLanguageClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setLanguageIsOpened(!languageIsOpened)
  }

  return (
    <>
      <Wrapper onClick={handleLanguageClick} isOpened={languageIsOpened}>
        <Text variant="t8">ENG</Text>
      </Wrapper>
      <LanguageBar isOpened={languageIsOpened} />
    </>
  )
}

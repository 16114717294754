import React from 'react'

import { ControllerRenderProps } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'

import { ApplicationE, ApplicationFormFieldsT } from 'data/application'
import { getStyles, styled, theme } from 'ui/styles'

const Input = styled(TextareaAutosize)<{ isValid: boolean; isNotEmpty: boolean }>`
  display: inline-flex;
  ${getStyles('t8')}
  align-self: start;
  border-radius: 8px;
  border: 1px solid
    ${({ isValid, isNotEmpty }) =>
      isValid ? (isNotEmpty ? theme.palette.blue_60 : theme.palette.blue_20) : theme.palette.red};
  width: 100%;
  min-height: 40px;
  padding: 8px 16px 8px 16px;
  color: ${theme.palette.blue_60};

  transition-property: color, border, box-shadow;
  transition-duration: ${theme.transition.hover}ms;

  &::placeholder {
    color: ${theme.palette.blue_20};
  }

  @media (hover: hover) {
    &:hover {
      border: 1px solid white;
      box-shadow: 0 0 0px 1px ${theme.palette.green}, 0px 4px 5px rgba(0, 0, 0, 0.15);

      &:focus,
      &:active,
      &:target {
        border: 1px solid white;
      }
    }

    &:focus,
    &:active,
    &:target {
      border: 1px solid ${theme.palette.green};
    }
  }

  &:focus,
  &:active,
  &:target {
    border: 1px solid ${theme.palette.green};
  }
`

type Props = {
  value: string
  isValid: boolean
  isBig?: boolean
  placeholder?: string
  field: ControllerRenderProps<ApplicationFormFieldsT, ApplicationE>
}

export const Textarea: React.FC<Props> = ({ value, placeholder, isValid, isBig, field }) => {
  const isNotEmpty = Boolean(value)

  return (
    <Input
      isValid={isValid}
      minRows={isBig ? 3 : 1}
      maxRows={8}
      rows={isBig ? 3 : 1}
      placeholder={placeholder}
      isNotEmpty={isNotEmpty}
      {...field}
    />
  )
}

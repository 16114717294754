import { MutableRefObject, useEffect, useState } from 'react'

export const useIsInViewport = (ref: MutableRefObject<HTMLDivElement>) => {
  const [isInViewPort, setIsInViewport] = useState(false)

  useEffect(() => {
    if (isInViewPort) {
      return
    }
    const handleScroll = () => {
      const { bottom } = ref.current.getBoundingClientRect()
      return setIsInViewport(window.innerHeight - bottom > 10)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [ref, isInViewPort])

  return isInViewPort
}

import { styled } from 'ui/styles'

export const Link = styled.a`
  color: ${({ theme }) => theme.palette.green};
  transition: color ${({ theme }) => theme.transition.hover}ms linear;

  * {
    transition: stroke ${({ theme }) => theme.transition.hover}ms linear;
    stroke: ${({ theme }) => theme.palette.white};
  }

  &:hover {
    text-decoration: underline;
  }
`

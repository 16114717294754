import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'root-reducer'
import { breakpoints } from 'app/shared'
import { getStyles, styled, theme } from 'ui/styles'
import { Image, SlideAnimation, Text } from 'ui/components'

import { ItemT } from './AboutUs'

import { ReactComponent as Sprite2 } from 'ui/icons/sprite2.svg'

import { ReactComponent as NumerationLogo1 } from 'ui/icons/numeration/icon_1.svg'
import { ReactComponent as NumerationLogo2 } from 'ui/icons/numeration/icon_2.svg'
import { ReactComponent as NumerationLogo3 } from 'ui/icons/numeration/icon_3.svg'
import { ReactComponent as NumerationLogo4 } from 'ui/icons/numeration/icon_4.svg'

import { ReactComponent as NumberIcon1 } from 'ui/icons/numeration/01.svg'
import { ReactComponent as NumberIcon2 } from 'ui/icons/numeration/02.svg'
import { ReactComponent as NumberIcon3 } from 'ui/icons/numeration/03.svg'
import { ReactComponent as NumberIcon4 } from 'ui/icons/numeration/04.svg'

type DirectionType = (ItemT & { icon_number: JSX.Element; icon: JSX.Element })[][]

const DIRECTIONS: DirectionType = [
  [
    {
      title: 'Веб-сервисы',
      info: 'Реализуем функционально сложные и высоконагруженные веб-сервисына актуальном стеке технологий.',
      icon: <NumerationLogo1 />,
      icon_number: <NumberIcon1 />
    },
    {
      title: 'Мобильные приложения',
      info: 'Реализуем функционально сложные мобильные приложения любых направлений. В числе наших проектов:  web-сервис для цифрового банка, решение для клинических исследований лекарственных препаратов, разработка ERP-системы и др.',
      icon: <NumerationLogo2 />,
      icon_number: <NumberIcon2 />
    },
    {
      title: 'Автоматизация бизнес-процессов',
      info: 'Мы легко расширяем команду при возрастающих спросах, делаем системы быстро и без лишней бюрократии. Наши менеджеры проектов умеют работать с большим количеством заинтересованных лиц со стороны заказчика и обрабатывать большие объемы требований',
      icon: <NumerationLogo3 />,
      icon_number: <NumberIcon3 />
    },
    {
      title: 'DevOps',
      info: 'Создаем, поддерживаем и документируем Вашу инфраструктуру с помощью самых современных технологий.',
      icon: <NumerationLogo4 />,
      icon_number: <NumberIcon4 />
    }
  ],
  [
    {
      title: 'Web Services',
      info: 'We implement functionally complex and highly loaded web services on the current technology stack.',
      icon: <NumerationLogo1 />,
      icon_number: <NumberIcon1 />
    },
    {
      title: 'Mobile applications',
      info: 'We implement mobile applications of any directions. Among our projects: web-service for digital bank, solution for clinical research of medicines, development of marketplace, ERP-systems, etc.',
      icon: <NumerationLogo2 />,
      icon_number: <NumberIcon2 />
    },
    {
      title: 'Business Process Automation',
      info: 'We easily expand the team with increasing demands, make systems quickly and without unnecessary bureaucracy. Our project managers know how to work with a large number of customer stakeholders and handle large volumes of requirements.',
      icon: <NumerationLogo3 />,
      icon_number: <NumberIcon3 />
    },
    {
      title: 'DevOps',
      info: 'We build, maintain, and document your infrastructure with state-of-the-art technology.',
      icon: <NumerationLogo4 />,
      icon_number: <NumberIcon4 />
    }
  ]
]

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
`

const Content = styled.div`
  flex-direction: column;
  width: 100%;

  padding: 210px 138px 128px;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 96px 33px 96px;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 40px 24px 96px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 32px 16px 270px;
  }
`

export const DirectionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  &:not(:last-child) {
    margin-bottom: 96px;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    &:nth-child(odd) {
      flex-direction: column-reverse;
    }
    flex-direction: column-reverse;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

export const DirectionContent = styled(SlideAnimation)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    flex-direction: column;
  }
`

export const DirectionLogo = styled(SlideAnimation)`
  display: flex;
  width: 100%;
  justify-content: center;

  svg {
    height: 267px;
  }

  @media (max-width: ${breakpoints.desktop - 1}px) {
    svg {
      height: 200px;
    }
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    svg {
      height: 160px;
    }

    margin-top: 16px;
  }
`

export const DirectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    width: 100%;
  }
`

export const DirectionTitle = styled(Text)`
  margin-top: 35px;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    margin-top: 16px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('h8')};
  }
`

export const DirectionInfo = styled(Text)`
  margin-top: 24px;
  max-width: 500px;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    ${getStyles('t6')};
    max-width: unset;
  }
`

export const DirectionNumberLogo = styled.div`
  margin-right: 59px;
`

export const StyledSphere4 = styled(Image)`
  position: absolute;
  width: 466px;
  height: 466px;
  right: 0px;
  top: 460px;

  transition: left, top, ${theme.transition.slide}ms ease;

  z-index: -1;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    top: 300px;
    transform: translateX(200px);
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 330px;
    height: 330px;
    transform: translateX(110px);
  }
`

export const StyledSphere5 = styled(Image)`
  position: absolute;
  width: 318px;
  height: 318px;
  left: 0px;
  bottom: 0px;
  transition: left, top, ${theme.transition.slide}ms ease;
  z-index: -1;
  transform: translate(-50px, 150px);

  @media (max-width: ${breakpoints.desktop - 1}px) {
    transform: translate(-50px, 200px);
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    display: none;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    display: unset;
    width: 151px;
    height: 150px;
    transform: translate(-20px, 30px);
  }
`
export const StyledSprite2 = styled(Sprite2)`
  position: absolute;
  width: 627px;
  height: 505px;
  left: 0;
  bottom: -56px;
  z-index: -1;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    transform: translateY(150px);
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    bottom: 20px;
    width: 296px;
    height: 239px;
    transform: unset;
  }
`

export const Directions = () => {
  const language = useSelector((state: RootState) => state.language.language)

  return (
    <Wrapper>
      <Content>
        {DIRECTIONS[language].map((el, i) => (
          <DirectionRow key={el.title}>
            <DirectionLogo direction={i % 2 ? 'left' : 'right'}>{el.icon}</DirectionLogo>
            <DirectionContent direction={i % 2 ? 'right' : 'left'}>
              <DirectionNumberLogo>{el.icon_number}</DirectionNumberLogo>
              <DirectionContentWrapper>
                <DirectionTitle variant="h3">{el.title}</DirectionTitle>
                <DirectionInfo variant="t1">{el.info}</DirectionInfo>
              </DirectionContentWrapper>
            </DirectionContent>
          </DirectionRow>
        ))}
      </Content>
      <StyledSphere4 name="spheres/sphere4.png" width="100%" height="100%" />
      <StyledSphere5 name="spheres/sphere4.png" width="100%" height="100%" />
      <StyledSprite2 />
    </Wrapper>
  )
}

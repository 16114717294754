import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setLanguage } from 'store/language'
import { breakpoints } from 'app/shared'
import { Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div<{ isOpened: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: auto;
  right: 36px;
  top: 50px;
  background-color: ${theme.palette.blue_10};

  visibility: ${({ isOpened }) => (isOpened ? 'visible' : 'hidden')};
  opacity: ${({ isOpened }) => (isOpened ? 1 : 0)};
  transition: opacity, transform, ${theme.transition.hover}ms ease;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    right: 24px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    right: 54px;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0px 8px;
  background-color: transparent;
  color: ${theme.palette.blue_60};

  transition: background-color, color, ${theme.transition.hover}ms ease;

  &:hover {
    background-color: ${theme.palette.green};
    color: ${theme.palette.white};
  }
`

type Props = {
  isOpened: boolean
}

export const LanguageBar: React.FC<Props> = ({ isOpened }) => {
  const dispatch = useDispatch()
  const languages = ['ENG (English)', 'ARM (Armenian)']
  const onLangClick = useCallback((i: number) => dispatch(setLanguage(i)), [dispatch])
  return (
    <Wrapper isOpened={isOpened}>
      {languages.map((el, i) => (
        <Item key={i} onClick={() => onLangClick(i)}>
          <Text variant="t8">{el}</Text>
        </Item>
      ))}
    </Wrapper>
  )
}

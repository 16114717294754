import React from 'react'

import { breakpoints, useDimensions } from 'app/shared'
import { Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 18px;
    }
  }
`

const Title = styled(Text)`
  margin-bottom: 8px;
  color: ${theme.palette.gray};
`

const Content = styled.div`
  display: inline-flex;
  flex-direction: row;
`

const StyledIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 16px;
  color: ${theme.palette.white};
`

const ContactText = styled(Text)`
  display: inline-flex;
  max-width: 320px;
  color: ${theme.palette.white};
`

export type ContactPropsT = {
  title: string
  icon: JSX.Element
  contact: string
}

export const Contact: React.FC<ContactPropsT> = ({ title, icon, contact }) => {
  const { isMobile, isTablet } = useDimensions()
  return (
    <Wrapper>
      <Title variant={isMobile ? 't7' : 't6'}>{title}</Title>
      <Content>
        <StyledIcon>{icon}</StyledIcon>
        <ContactText variant={isMobile || isTablet ? 't2' : 't0'}>{contact}</ContactText>
      </Content>
    </Wrapper>
  )
}

import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'

import { configureStore } from 'configure-store'

import { GlobalStyles, theme, ThemeProvider } from 'ui/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { App } from 'App'

const root = createRoot(document.getElementById('root')!)
const queryClient = new QueryClient()
const store = configureStore()

root.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </ReduxProvider>
)

import React from 'react'

import { breakpoints, useDimensions } from 'app/shared'
import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: center;
  height: 100%;
  margin-right: 19px;
  color: ${theme.palette.white};
  transition: color ${theme.transition.hover}ms ease;

  cursor: pointer;

  &::after {
    content: '';
    position: relative;
    width: 0%;
    height: 1px;
    background-color: ${theme.palette.white};

    transition: background-color, width, ${theme.transition.hover}ms ease;
  }

  @media (hover: hover) {
    &:hover {
      color: ${theme.palette.green};

      &::after {
        width: 100%;
        background-color: ${theme.palette.green};
      }
    }
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    &:not(:last-child) {
      margin-bottom: 19px;
    }
  }
`

const StyledText = styled(Text)``

type Props = {
  title: string
  onClick: (ev: React.MouseEvent) => void
}

export const AppBarItem: React.FC<Props> = ({ title, onClick }) => {
  const { isMobile } = useDimensions()
  return (
    <Wrapper onClick={(ev) => onClick(ev)}>
      <StyledText variant={isMobile ? 't0' : 't8'}>{title}</StyledText>
    </Wrapper>
  )
}

import React from 'react'

import { breakpoints, useDimensions, useScroll } from 'app/shared'
import { styled, theme } from 'ui/styles'

import { ReactComponent as Logo } from 'ui/icons/logo.svg'

import { AppBarItems, LanguagePicker } from '../molecules'
import { BurgerIcon } from '../atoms'

const Wrapper = styled.nav`
  display: flex;
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  height: 68px;
  padding: 0px 36px;
  align-items: center;
  background-color: ${theme.palette.black};
  z-index: 50;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 0px 24px;
  }
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 0px 16px;
  }
`

const LogoWrapper = styled.div`
  display: inline-flex;
  justify-items: start;
  width: 100%;
  margin-right: 20px;
`

const StyledLogo = styled(Logo)`
  width: 37px;
  height: 30px;
`

const BlurBackground = styled.div<{ isOpen: boolean }>`
  display: flex;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity ${theme.transition.hover}ms ease;
  z-index: 5;

  backdrop-filter: blur(10px);
  -web-kit-backdrop-filter: blur(10px);
`

export const AppBar: React.FC = () => {
  const [burgerIsOpened, setBurgerIsOpened] = React.useState<boolean>(false)
  const body = document.body

  const { isMobile } = useDimensions()
  const { goTo } = useScroll()

  React.useEffect(() => {
    if (burgerIsOpened) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [body.classList, burgerIsOpened])

  const handleBurgerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setBurgerIsOpened(!burgerIsOpened)
  }

  const handleItemClick = (i: number) => {
    goTo(i)
    setBurgerIsOpened(false)
  }

  return (
    <>
      {isMobile && <BlurBackground isOpen={burgerIsOpened} onClick={() => setBurgerIsOpened(false)} />}
      <Wrapper>
        <LogoWrapper>
          <StyledLogo />
        </LogoWrapper>
        <AppBarItems burgerIsOpened={burgerIsOpened} handleItemClick={handleItemClick} />
        {/* <LanguagePicker /> */}
        {isMobile && <BurgerIcon onClick={handleBurgerClick} opened={burgerIsOpened} />}
      </Wrapper>
    </>
  )
}

import React from 'react'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import HttpService from 'services/main.services'
import { ApplicationFormFieldsT } from 'data/application'
import { useDispatch } from 'react-redux'
import { setModalOpen } from 'store'

export type SendRequestReturnT = ReturnType<typeof useSendRequest>

export const useSendRequest = (cb: () => void, endpoint: string) => {
  const dispatch = useDispatch()
  const [queryResult, setQueryResult] = React.useState<string | undefined>(undefined)

  const {
    isLoading,
    mutate: sendRequest,
    isError,
    isSuccess
  } = useMutation<AxiosResponse<any> | undefined, AxiosError, ApplicationFormFieldsT>(
    async <T>(data: T) => {
      setQueryResult(undefined)

      return await HttpService.createApplication(data, endpoint)
    },
    {
      onSuccess: (res) => {
        cb()
        dispatch(
          setModalOpen({
            modalIsOpen: true,
            modalType: {
              type: 'status',
              status: { isError: false, message: 'Успешно!' }
            }
          })
        )
        setQueryResult(res!.data)
      },
      onError: (err: AxiosError) => {
        cb()
        dispatch(
          setModalOpen({
            modalIsOpen: true,
            modalType: { type: 'status', status: { isError: true, message: `${err.message}` } }
          })
        )
        setQueryResult(err.message)
      }
    }
  )

  return { queryResult, isLoading, sendRequest, isError, isSuccess }
}

import React from 'react'
import { useForm } from 'react-hook-form'

import { applicationForm, ApplicationFormFieldsT } from 'data/application'
import { Divider, Loader, OutlineButton, Text, TextInput } from 'ui/components'
import { breakpoints, SendRequestReturnT, useDimensions, useSendRequest } from 'app/shared'
import { styled, theme } from 'ui/styles'

import { ModalTemplate } from './templates'
import { useSelector } from 'react-redux'
import { RootState } from 'root-reducer'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const FieldWrapper = styled.section`
  display: inline-flex;
  flex-direction: row;
`

const SubmitWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const FormFooter = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`
const SubmitButton = styled(OutlineButton)`
  max-width: 215px;
  @media (max-width: ${breakpoints.mobile - 1}px) {
    max-width: 100%;
    margin-bottom: 16px;
  }
`

const ERROR_LABEL = ['Выделенные поля заполнены неверно', 'The highlighted fields are filled out incorrectly']

const SUBMIT_LABEL = ['Отправить', 'Send']

const APPLICATION_HEADER = ['Связаться с нами', 'Contact Us']

type Props = {
  onClose?: () => void
}

const ApplicationHeader = ({ isMobile, languageNum }: { isMobile: boolean; languageNum: number }) => (
  <Text variant={isMobile ? 't0' : 'h5'} color="black">
    {APPLICATION_HEADER[languageNum]}
  </Text>
)

export const ApplicationModal: React.FC<Props> = ({ onClose = () => {} }) => {
  const { isMobile } = useDimensions()

  const language = useSelector((state: RootState) => state.language.language)

  const {
    control,
    formState: { isValid, errors },
    reset,
    resetField,
    handleSubmit
  } = useForm<ApplicationFormFieldsT>({ mode: 'onBlur' })

  const onSended = () => {
    reset()
  }

  const { sendRequest, isLoading }: SendRequestReturnT = useSendRequest(onSended, 'feedback-applications')

  const onSubmit = async (fields: ApplicationFormFieldsT) => {
    sendRequest(fields)
  }

  return (
    <ModalTemplate header={<ApplicationHeader isMobile={isMobile} languageNum={language} />} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {applicationForm[language].map((el) => (
          <FieldWrapper key={el.field}>
            <TextInput
              control={control}
              input={el}
              clearField={() => resetField(el.field)}
              isValid={!Boolean(errors[el.field])}
            />
          </FieldWrapper>
        ))}
        {Boolean(Object.keys(errors).length) && (
          <Text variant={isMobile ? 't7' : 't5'} color={theme.palette.red}>
            {`* ${ERROR_LABEL[language]}`}
          </Text>
        )}
        <SubmitWrapper>
          <Divider color={theme.palette.blue_10} withMargin />
          <FormFooter>
            <SubmitButton type="submit" isDisabled={!isValid || isLoading}>
              {isLoading ? <Loader /> : <Text variant="t0">{SUBMIT_LABEL[language]}</Text>}
            </SubmitButton>
          </FormFooter>
        </SubmitWrapper>
      </Form>
    </ModalTemplate>
  )
}

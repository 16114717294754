import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'root-reducer'

enum TypePhase {
  Typing,
  Pause,
  Deleting
}

const TYPING_PAUSE_MS = 1500
const TYPING_INTERVAL_MS = 50
const DELETING_INTERVAL = 20
const DELETING_PAUSE_MS = 300

export const useTypeWriter = (textData: string[]) => {
  const [phase, setPhase] = React.useState<TypePhase>(TypePhase.Typing)
  const [displayText, setDisplayText] = React.useState<string>('')
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

  const applicationIsOpen = useSelector((state: RootState) => state.modal.modalIsOpen)

  React.useEffect(() => {
    if (applicationIsOpen) {
      return
    }
    switch (phase) {
      case TypePhase.Typing: {
        const next = textData[selectedIndex].slice(0, displayText.length + 1)

        if (next === displayText) {
          setPhase(TypePhase.Pause)
          return
        }

        const timeout = setTimeout(() => {
          setDisplayText(next)
        }, TYPING_INTERVAL_MS)

        return () => clearTimeout(timeout)
      }
      case TypePhase.Deleting: {
        if (!displayText) {
          const timeout = setTimeout(() => {
            const nextIndex = selectedIndex + 1
            setSelectedIndex(textData[nextIndex] ? nextIndex : 0)
            setPhase(TypePhase.Typing)
          }, DELETING_PAUSE_MS)
          return () => clearTimeout(timeout)
        }

        const nextRemaining = textData[selectedIndex].slice(0, displayText.length - 1)

        const timeout = setTimeout(() => {
          setDisplayText(nextRemaining)
        }, DELETING_INTERVAL)

        return () => clearTimeout(timeout)
      }

      case TypePhase.Pause:
      default:
        const timeout = setTimeout(() => {
          setPhase(TypePhase.Deleting)
        }, TYPING_PAUSE_MS)

        return () => clearTimeout(timeout)
    }
  }, [displayText, applicationIsOpen, phase, selectedIndex, textData])

  return { displayText }
}

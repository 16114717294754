import React, { PropsWithChildren } from 'react'
import { css } from 'styled-components'

import { styled, theme } from 'ui/styles'

const Wrapper = styled.div<{ isError: boolean; isDisabled: boolean }>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: fit-content;
  min-height: 48px;

  padding: 9px 23px;
  border-radius: 16px;
  color: ${({ isError, isDisabled }) => (isError || isDisabled ? `${theme.palette.gray}` : `${theme.palette.white}`)};
  background-color: ${({ isError, isDisabled }) =>
    isError || isDisabled ? `${theme.palette.black}` : `${theme.palette.green}`};

  transition: background-color ${theme.transition.hover}ms ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.palette.green};
    }
  }

  ${({ isError, isDisabled }) =>
    (isError || isDisabled) &&
    css`
      border: 1px solid ${isError ? `${theme.palette.red}` : `${theme.palette.gray}`};
      pointer-events: none;
    `}
`

type Props = {
  isError?: boolean
  isDisabled?: boolean
}

export const PrimaryButton: React.FC<PropsWithChildren<Props>> = ({
  isError = false,
  isDisabled = false,
  children
}) => (
  <Wrapper isError={isError} isDisabled={isDisabled}>
    {children}
  </Wrapper>
)
